import { ClipLoader } from 'react-spinners';

const Button = ({
    id,
    children,
    icon,
    onClick,
    isLoading,
    buttonType,
    textColor,
    bgColor,
    wrapperclass,
    paddingClass = ' px-8 py-3.5',
    loader,
    gtmTag = '',
    cursorClass = 'cursor-pointer',
    loaderColor = '#ffffff',
    ...property
}) => {
    const backgroundColor = bgColor ? bgColor : 'bg-primary-500';
    const loadingIcon = loader ? (
        loader
    ) : (
        <ClipLoader color={loaderColor} size={20} />
    );
    return (
        <div className={`${wrapperclass}`} onClick={onClick}>
            {(() => {
                if (
                    property.design == 'standard' &&
                    property.type == 'primary'
                ) {
                    return (
                        <button
                            type={buttonType}
                            className={`relative ${paddingClass} flex justify-center gap-2 items-center capitalize ${textColor ? textColor : 'text-basicWhite'
                                }  rounded-lg  text-${property.size}  ${property.className
                                } ${property.state === 'disabled'
                                    ? 'bg-primary-100 opacity-50 text-primary-500'
                                    : backgroundColor
                                }
                            ${property.borderClass ? property.borderClass : ''}
                            `}
                            disabled={
                                property.state === 'disabled' || isLoading
                            }
                            id={id}
                            gtmTag={gtmTag ? gtmTag : id}
                        >
                            {children}
                            {isLoading && loadingIcon}
                        </button>
                    );
                }
                if (property.design == 'standard' && property.type == 'ghost') {
                    return (
                        <button
                            type={buttonType}
                            disabled={
                                property.state === 'disabled' || isLoading
                            }
                            className={`relative ${paddingClass} flex border gap-2 border-gray-600 rounded-lg justify-center capitalize text-${property.size
                                }  ${property.className} ${textColor ? textColor : 'text-primary-500'
                                }
                                ${isLoading ? 'cursor-not-allowed' : cursorClass}`}
                            id={id}
                            gtmTag={gtmTag ? gtmTag : id}
                        >
                            {isLoading && loadingIcon}
                            {children}

                        </button>
                    );
                }
            })()}
        </div>
    );
};
export default Button;