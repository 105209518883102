module.exports = {
    basicBlack: '#000000',
    basicWhite: '#FFFFFF',
    primary: {
        50: '#E7EEF6',
        70: '#D6E4F0',
        100: '#93B1CA',
        200: '#5C89AF',
        300: '#1F6295',
        400: '#0F5299',
        500: '#003975',
        600: '#002E5E',
        700: '#00274F',
        800: '#001F3F',
        900: '#00172F',
    },
    secondary: {
        50: '#FFF9EF',
        100: '#FEF2DB',
        200: '#FFEACB',
        300: '#FDD17F',
        400: '#FFBA35',
        500: '#FFA400',
        600: '#EBA31C',
        700: '#D89211',
        800: '#AB7209',
        900: '#855D13',
    },
    gray: {
        50: '#F9F9FB',
        100: '#F4F6F8',
        200: '#E5E5EB',
        300: '#D1D1DB',
        400: '#9C9CAF',
        500: '#676E8B',
        600: '#4B4B63',
        700: '#373751',
        800: '#1F1F37',
        900: '#1A202C',
    },
    purple: {
        50: '#D9D9FF',
        100: '#B3B3F9',
        200: '#7575F7',
        300: '#4D4DF1',
        400: '#3D3DC5',
        500: '#F7CDFE',
    },
    yellow: {
        500: '#eab308',
    },
    green: {
        50: '#F0FFFB',
        100: '#C9FDEE',
        200: '#86F7D9',
        300: '#00EEB5',
        400: '#00CF8D',
        500: '#CEEBAA',
    },
    pink: {
        50: '#FFFAFA',
        100: '#FFF0F1',
        200: '#FFE3E3',
        300: '#FFD6D7',
        400: '#FFC8CA',
        800: '#FF666C',
    },
    apricot: {
        50: '#FFF3EB',
        100: '#FFD6B8',
    },
    periwinkle: {
        50: '#F2F2FC',
        100: '#CFCFF5',
        700: '#5656DC',
    },
    tiffanyBlue: {
        50: '#E4F6F5',
        100: '#A3E1DE',
    },
    uranianBlue: {
        50: '#EBF6FF',
        100: '#C2E3FF',
        500: '#4DADFF',
    },
    topaz: {
        50: '#FEF2DB',
        100: '#FDD17F',
        600: '#FBA504',
        700: '#FFDFC7',
    },
    leafGreen: {
        100: '#D6F3D9',
        300: '#8BF096',
        600: '#16A34A',
    },
    bgColor: {
        50: '#F7F7FE',
        100: '#ECECF7',
    },
    links: {
        600: '#002E5E',
        800: '#001F3F',
    },
    semantic: {
        'info-light': '#E5F5FC',
        'info-base': '#017AAD',
        'success-light': '#EAF3EB',
        'success-base': '#289A3F',
        'warning-light': '#FFEFE4',
        'warning-base': '#F37921',
        'error-light': '#FCEAEA',
        'error-base': '#E05C5C',
    },
    riskRange: {
        orange: '#F37921',
        yellow: '#FFBA35',
        green: '#289A3F',
    },
    transparent: 'transparent',
};