const Card = ({
    children,
    paddingClass = 'p-5',
    verticalSpace = 'space-y-4',
    borderRadius = 'rounded-xl',
    shadow = 'shadow-lg',
    bgColor = 'bg-basicWhite',
    ...property
}) => {
    return (
        <div
            className={`items-center ${shadow} ${bgColor} ${borderRadius} ${paddingClass} ${property.className} `}
        >
            <div className={verticalSpace}>{children}</div>
        </div>
    );
};
export default Card;