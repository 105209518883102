const Toggle = ({ className, toggleInputChecked, toggleId }) => {
    return (
        <label className={`switch ${className}`}>
            <input
                type="checkbox"
                onChange={toggleInputChecked}
                id={toggleId}
            />
            <span className="slider round"></span>
        </label>
    );
};
export default Toggle;
