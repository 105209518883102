// TODO: Move to enums folder
const KycStatusEnums = {
    PENDING: 'Pending',
    MANUAL_VERIFICATION_REQUIRED: 'ManualVerificationRequired',
    ESIGN_PENDING: 'ESignPending',
    VERIFIED: 'Verified',
    REJECTED: 'Rejected',
    DEMAT_VERIFICATION_PENDING: 'DematVerificationPending',
};

Object.freeze(KycStatusEnums);

module.exports = KycStatusEnums;
