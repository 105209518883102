import {hasWindow} from '@services/http.service';
import * as CsrHttpService from '@http/csr-http.service';
import * as SsrHttpService from '@http/ssr-http.service';
import {
    CREATE_WITHDRAWAL_REQUEST,
    GET_ALL_WALLET_TRANSACTIONS_DATA_FILE_NAME,
    GET_IN_PROGRESS_WITHDRAWAL_REQUESTS,
    GET_JIRAAF_SAFE_TRANSACTIONS,
    GET_PENDING_WITHDRAWAL_REQUESTS,
    GET_WALLET,
    GET_WITHDRAWAL_REQUESTS,
    INITIATE_WITHDRAWAL_REQUEST,
} from './urls/jiraafSafe';

export const GetJiraafSafeTransactions = (
    investorId,
    ctx = null
) => {
    const url = GET_JIRAAF_SAFE_TRANSACTIONS(investorId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getWallet = (investorId,ctx=null) => {
    const url = GET_WALLET(investorId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const GetAllWalletTransactionsFileName = (investorId, ctx = null) => {
    const url = GET_ALL_WALLET_TRANSACTIONS_DATA_FILE_NAME(investorId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const createWithdrawalRequest = (data, ctx = null) => {
    const url = CREATE_WITHDRAWAL_REQUEST();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const getInProgressWithdrawalRequests = (investorId, ctx = null) => {
    const url = GET_IN_PROGRESS_WITHDRAWAL_REQUESTS(investorId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const GetPendingWithdrawalRequests = (page, limit, ctx = null) => {
    const url = GET_PENDING_WITHDRAWAL_REQUESTS(page, limit);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};
export const GetWithdrawalInitiatedRequests = (page, limit, ctx = null) => {
    const url = GET_WITHDRAWAL_REQUESTS(page, limit);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};
export const InitiateWithdrawalRequest = (id, data, ctx = null) => {
    const url = INITIATE_WITHDRAWAL_REQUEST(id);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url,data);
    }
    return SsrHttpService.postWithAuth(url, ctx);
};
