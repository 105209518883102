import { useState } from 'react';
import Text from './Text';

const ToolTip = ({
    content,
    children,
    position = 'absolute top-6 md:top-8 -left-28 ml-2 sm:ml-0 md:left-auto md:right-0',
    className,
    image = '/images/info-new.svg',
    text,
    textStyle = 'ml-1 text-primary-500 md:font-medium text-xs',
    ...property
}) => {
    const [isShow, setIsShown] = useState(false);
    return (
        <div
            className={`relative flex flex-col items-center justify-center cursor-pointer group ${className}`}
        >
            {isShow && (
                <div
                    className={`${position} flex flex-col mb-6  border rounded-lg border-gray-200 overflow-hidden drop-shadow-lg filter z-50"`}
                >
                    <span
                        className={`relative z-30  px-4 py-3 p5-regular md:p4-regular bg-basicWhite rounded-lg shadow-lg  overflow-clip ${property.contentHeight
                            ? property.contentHeight
                            : 'w-72 md:w-122 h-full'
                            }`}
                    >
                        {content}
                    </span>
                </div>
            )}
            <div
                className="flex items-center justify-center ml-1 cursor-default"
                onClick={() => {
                    setIsShown(!isShow);
                }}
                onMouseEnter={() => {
                    setIsShown(true);
                }}
                onMouseLeave={() => setIsShown(false)}
            >
                <img src={image} />
                {text && <Text content={text} className={textStyle} />}
            </div>
        </div>
    );
};

export default ToolTip;
