import {
    setUserData,
    updateUserData,
    setUserAttribute,
    logoutUser,
    updateUserID,
    logEvent,
    updateUserName,
} from "@services/moengage/moengage";
import moengageEventConstants from "@services/moengage/moengageEventConstants";
import moengageEventArgsConstants from "@services/moengage/moengageEventArgsConstants";
import moengageAttributesConstants from "@services/moengage/moengageAttributesConstants";

module.exports = {
    setUserData,
    updateUserData,
    setUserAttribute,
    logoutUser,
    updateUserID,
    logEvent,
    updateUserName,
    moengageEventConstants,
    moengageEventArgsConstants,
    moengageAttributesConstants,
}