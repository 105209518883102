// Function to append query parameters
const createUrl = (base, params) => {
    const query = Object.entries(params || {})
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&');
    return `${base}${query ? `?${query}` : ''}`;
};

const addParam = (queryParams, param) => {
    return queryParams ? queryParams + `&${param}` : `?${param}`;
};

export { createUrl, addParam };
