import { EntityType } from '@helpers/enums/investor-enums';
import KycStatusEnums from '@helpers/kyc-status';

const isKycVerified = (status) => status === KycStatusEnums.VERIFIED;

const isKycPending = (status) => status !== KycStatusEnums.VERIFIED;

const isManualVerificationRequired = (status) =>
    status === KycStatusEnums.MANUAL_VERIFICATION_REQUIRED;

const isKycVerifiedOrManualVerificationRequired = (status) =>
    isKycVerified(status) || isManualVerificationRequired(status);

const isIndividual = (accountType) =>
    accountType &&
    accountType.toLowerCase() === EntityType.INDIVIDUAL.toLowerCase();

const hasAccountType = (accountType) => !!accountType;

const isIfa = (auth) => {
    return auth?.entityType === 'ifa';
};

const isNotIfa = (auth) => {
    if (auth?.entityType) {
        return auth?.entityType !== 'ifa';
    }
    return false;
};

const showReferral = (auth) => {
    return !isIfa(auth);
};

export {
    isKycVerified,
    isKycPending,
    isManualVerificationRequired,
    isKycVerifiedOrManualVerificationRequired,
    isIndividual,
    hasAccountType,
    isIfa,
    isNotIfa,
    showReferral,
};
