import { getAuth } from '@services/identity.service';
import { getInvestorNSEBankDetails } from '@services/investments.service';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const auth = getAuth();

const fetchInvestorNSEBankDetails = async () => {
    const auth = getAuth();
    const response = await getInvestorNSEBankDetails(auth?.id);
    if (response?.status) {
        return response.entity;
    }
};

export const getBeneficiaryDetails = async () => {
    const beneficiaryDetails = await fetchInvestorNSEBankDetails();
    const data = [
        {
            title: 'Beneficiary Name',
            value: beneficiaryDetails?.beneficiaryName ? beneficiaryDetails?.beneficiaryName : '-',
        },
        {
            title: 'Bank Name',
            value: beneficiaryDetails?.bankName ? beneficiaryDetails?.bankName : '-',
        },
        {
            title: 'IFSC Code',
            value: beneficiaryDetails?.bankIFSCCode ? beneficiaryDetails?.bankIFSCCode : '-',
        },
        {
            title: 'Account Number',
            value: beneficiaryDetails?.accountNumber ? beneficiaryDetails?.accountNumber : '-',
        },
        {
            title: 'Account Type',
            value: beneficiaryDetails?.accountType ? beneficiaryDetails?.accountType : 'Current',
        },
    ];
    return data;
};

export const initializeStore = async () => {
    if (auth?.role !== 'Admin' && auth?.accountType) {
        useInvestmentStore.setState((state) => ({
            ...state,
            loading: false,
        }));
    }
};

const initialState = {
    loading: true,
    ModalComponent: null,
    showModal: false,
    investmentError: null,
};

const useInvestmentStore = create((set) => ({
    ...initialState,
    setInvestmentError: (investmentError) =>
        set((state) => ({
            ...state,
            investmentError,
        })),

    setShowModal: (showModal) =>
        set((state) => ({
            ...state,
            showModal,
        })),

    setModalComponent: (ModalComponent) =>
        set((state) => ({
            ...state,
            ModalComponent,
        })),

    setLoading: (loading) =>
        set((state) => ({
            ...state,
            loading,
        })),
}));

export default useInvestmentStore;