import React, { forwardRef } from 'react';

const InputWithIcon = forwardRef(
    (
        {
            id,
            register,
            type,
            placeholder,
            label,
            labelClass,
            error,
            onClick,
            onPaste,
            onBlur,
            borderWeight,
            focusStyle,
            borderColor = 'border-gray-300',
            inputpaddingClassName = 'px-4 py-3',
            errorClassName = '',
            readOnly = false,
            icon,
            ...inputProps
        },
        ref
    ) => {
        return (
            <div className="relative w-full">
                {label && (
                    <label
                        className={`block w-full text-left text-gray-900 p4-regular ${labelClass}`}
                    >
                        {label}
                    </label>
                )}
                <div className="relative flex">
                    <input
                        ref={ref} // Pass the forwarded ref
                        {...(register ? register(id) : {})}
                        id={id}
                        {...inputProps}
                        type={type}
                        onClick={onClick}
                        onPaste={onPaste}
                        onBlur={onBlur}
                        className={`p4-regular ${borderColor} ${
                            borderWeight ? borderWeight : 'border-1'
                        } ${inputProps.width ? inputProps.width : 'w-full'} ${
                            inputProps.height ? inputProps.height : 'h-12'
                        } ${inputpaddingClassName} rounded-lg mt-2 box-border ${
                            inputProps.className
                        } ${focusStyle ? focusStyle : ''} ${
                            inputProps?.disabled
                                ? '!bg-gray-100 text-gray-500 !border-1'
                                : ''
                        }`}
                        placeholder={placeholder}
                        readOnly={readOnly}
                        disabled={inputProps?.disabled || false}
                    />
                    {icon && (
                        <div className="absolute transform -translate-y-1/2 right-2 md:right-3 top-1/2">
                            {icon}
                        </div>
                    )}
                </div>
                {error && (
                    <div
                        className={`text-left p5-regular mt-2 text-semantic-error-base ${errorClassName}`}
                    >
                        {error.message}
                    </div>
                )}
            </div>
        );
    }
);

export default InputWithIcon;
