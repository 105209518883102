const Avatar = ({ icon, ...property }) => {
    return (
        <div
            className={`w-8 h-8 inline-block text-center leading-8 ${property.className}`}
        >
            <div className="flex items-center justify-center w-full h-full overflow-hidden text-center rounded-full shadow-inner cursor-pointer group bg-secondary-300">
                <span className="font-medium leading-10 align-middle text-secondary-600 group-hover:table-cell">
                    {icon}
                </span>
            </div>
        </div>
    );
};

export default Avatar;
