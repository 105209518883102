import * as yup from 'yup';
import texts from '../en.json';
import routes from '@helpers/routes';
import KycStatusEnums from '@helpers/kyc-status';
import nseStatusEnums from '@helpers/nse-status';
import { panRegexPattern } from '@helpers/regex-patterns';

const kycNavItems = [
    {
        step: 1,
        id: texts.kycNavItems[0].id,
        name: texts.kycNavItems[0].name,
        description: texts.kycNavItems[0].description,
        link: routes.kyc.basicDetails.base,
        icon: 'basic-details',
        activeIcon: 'basic-details-active',
        submenu: [
            {
                id: texts.kycNavItems[0].submenu[0].id,
                displayName: texts.kycNavItems[0].submenu[0].displayName,
                link: routes.kyc.basicDetails.residential,
                isCompleted: null,
            },
        ],
    },
    {
        step: 2,
        id: texts.kycNavItems[1].id,
        name: texts.kycNavItems[1].name,
        description: texts.kycNavItems[1].description,
        link: routes.kyc.idVerification.base,
        icon: 'id-verification',
        activeIcon: 'id-verification-active',
        submenu: [
            {
                id: texts.kycNavItems[1].submenu[0].id,
                displayName: texts.kycNavItems[1].submenu[0].displayName,
                link: routes.kyc.idVerification.pan,
                isCompleted: null,
            },
            {
                id: texts.kycNavItems[1].submenu[1].id,
                displayName: texts.kycNavItems[1].submenu[1].displayName,
                link: routes.kyc.idVerification.address,
                isCompleted: null,
                subText: texts.kycNavItems[1].submenu[1].subTexts,
            },
        ],
    },
    {
        step: 3,
        id: texts.kycNavItems[2].id,
        name: texts.kycNavItems[2].name,
        description: texts.kycNavItems[2].description,
        link: routes.kyc.additionalDetails.base,
        icon: 'user-icon',
        activeIcon: 'user-icon-active',
        submenu: [
            {
                id: texts.kycNavItems[2].submenu[0].id,
                displayName: texts.kycNavItems[2].submenu[0].displayName,
                link: routes.kyc.additionalDetails.personal,
                isCompleted: null,
                subText: texts.kycNavItems[2].submenu[0].subTexts,
            },
            {
                id: texts.kycNavItems[2].submenu[1].id,
                displayName: texts.kycNavItems[2].submenu[1].displayName,
                link: routes.kyc.additionalDetails.nominee,
                isCompleted: null,
                subText: texts.kycNavItems[2].submenu[1].subTexts,
            },
        ],
    },
    {
        step: 4,
        id: texts.kycNavItems[3].id,
        name: texts.kycNavItems[3].name,
        description: texts.kycNavItems[3].description,
        link: routes.kyc.bankDematDetails.base,
        icon: 'bank-details',
        activeIcon: 'bank-details-active',
        submenu: [
            {
                id: texts.kycNavItems[3].submenu[0].id,
                displayName: texts.kycNavItems[3].submenu[0].displayName,
                link: routes.kyc.bankDematDetails.bank,
                isCompleted: null,
                subText: texts.kycNavItems[3].submenu[0].subTexts,
            },
            {
                id: texts.kycNavItems[3].submenu[1].id,
                displayName: texts.kycNavItems[3].submenu[1].displayName,
                link: routes.kyc.bankDematDetails.demat,
                isCompleted: null,
                subText: [
                    texts.kycNavItems[3].submenu[1].subTexts[0],
                    texts.kycNavItems[3].submenu[1].subTexts[1],
                ],
            },
        ],
    },
    {
        step: 5,
        id: texts.kycNavItems[4].id,
        name: texts.kycNavItems[4].name,
        description: texts.kycNavItems[4].description,
        link: routes.kyc.aadhaar.base,
        icon: 'edit-confirm',
        activeIcon: 'edit-confirm-active',
        submenu: [
            {
                id: texts.kycNavItems[4].submenu[0].id,
                displayName: texts.kycNavItems[4].submenu[0].displayName,
                link: routes.kyc.aadhaar.liveliness,
                isCompleted: null,
                subText: texts.kycNavItems[4].submenu[0].subTexts,
                isBlockedInManualToSameStep: true,
            },
            {
                id: texts.kycNavItems[4].submenu[1].id,
                displayName: texts.kycNavItems[4].submenu[1].displayName,
                link: routes.kyc.aadhaar.signature,
                isCompleted: null,
                subText: texts.kycNavItems[4].submenu[1].subTexts,
                isBlockedInManualToSameStep: true,
            },
            {
                id: texts.kycNavItems[4].submenu[2].id,
                displayName: texts.kycNavItems[4].submenu[2].displayName,
                link: routes.kyc.aadhaar.esign,
                isCompleted: null,
                subText: texts.kycNavItems[4].submenu[2].subTexts,
            },
        ],
    },
];

const panRegex = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/;

const getPanValidationSchema = () => {
    return yup.object().shape({
        pan: yup
            .string()
            .matches(panRegexPattern, 'Enter a valid PAN number')
            .required('Please enter the PAN'),
    });
};

const getIdAadhaarConsentSchema = () => {
    return yup.object().shape({
        aadhaarConsent: yup
            .boolean()
            .oneOf([true], 'Please accept the aadhaar consent')
            .required(),
    });
};

const maskName = (investorName) => {
    const formattedName = investorName.replace(/[ +]/g, ',');
    const [firstName, middleName, lastName] = formattedName
        .split(',')
        .filter((n) => n);
    return `*${firstName.slice(1)} ${
        middleName ? '*' + middleName.slice(1) : ''
    } ${lastName ? '*' + lastName?.slice(1) : ''}`;
};

const getPanOfInvestor = (investorDocs) => {
    const panDoc = investorDocs.find((doc) => doc.type === 'Pan');
    return panDoc?.documentId;
};

const isPanNonIndividual = (pan) =>
    pan && pan?.toString()?.length === 10 && pan[3].toUpperCase() !== 'P';

const getButtonSize = (width) => (width < 768 ? 'small' : 'medium');
const getNseRegistrationStatus = (nseRegistrationStatus) => {
    if (nseRegistrationStatus !== 'Approved' || !nseRegistrationStatus) {
        return 'bg-[#FFEFE4] text-[#F37921]';
    }
    return 'bg-[#EAF3EB] text-[#289A3F]';
};

const getDetails = (kycStatus, nseRegistrationStatus) => {
    let title, description;
    switch (true) {
        case kycStatus === KycStatusEnums.MANUAL_VERIFICATION_REQUIRED:
            title = 'Your KYC verification is in progress';
            description =
                'You will receive an email once your KYC is verified or if we require any extra information.';
            break;
        case kycStatus === KycStatusEnums.VERIFIED &&
            (nseRegistrationStatus !== nseStatusEnums.APPROVED ||
                !nseRegistrationStatus):
            title = 'KYC submitted';
            description = (
                <>
                    <h1>
                        Your KYC details has been sent to the exchanges.
                        Verification could take up to 2 days. You will receive
                        an email confirmation once the verification is complete.
                    </h1>
                </>
            );
            break;
        case kycStatus === KycStatusEnums.VERIFIED &&
            nseRegistrationStatus === nseStatusEnums.APPROVED:
            title = 'KYC is verified!';
            description =
                'Your profile is now verified. You can start investing on your favourite opportunities.';
            break;
        default:
            break;
    }
    const details = {
        title,
        image:
            kycStatus === KycStatusEnums.VERIFIED ? 'success' : 'in-progress',
        kycSubmission:
            kycStatus === KycStatusEnums.VERIFIED ? 'Completed' : 'Pending',
        description,
        nseRegistration:
            nseRegistrationStatus !== nseStatusEnums.APPROVED
                ? ' In Progress'
                : 'Success',
    };
    return details;
};

 export {
     kycNavItems,
     panRegex,
     getPanValidationSchema,
     getIdAadhaarConsentSchema,
     maskName,
     getPanOfInvestor,
     isPanNonIndividual,
     getButtonSize,
     getNseRegistrationStatus,
     getDetails,
 };
