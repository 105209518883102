import * as CsrHttpService from '@http/csr-http.service';
import * as SsrHttpService from '@http/ssr-http.service';
import { hasWindow } from '@services/http.service';
import {
    CASHFREE_ELIGIBLE_BANKS,
    CREATE_INVESTMENT_URL,
    CREATE_OFFLINE_INVESTMENT_URL,
    CREATE_USER_CONSENT_URL,
    DOWNLOAD_INVESTMENT_CASHFLOWS_URL,
    DOWNLOAD_INVESTMENT_URL,
    DOWNLOAD_UPCOMING_REPAYMENT_URL,
    FREECHARGE_PAYMENT_RETRY_URL,
    GET_INVESTMENTS_ORDERS_BY_STATUS_URL,
    GET_INVESTMENTS_ORDERS_DETAILS_URL,
    GET_INVESTMENT_BY_ENTITY_OPPORTUNITY_URL,
    GET_INVESTOR_EXCHANGE_NSE_BANK_DETAILS,
    GET_INVESTOR_HAS_INVESTMENTS,
    GET_INVESTOR_INVESTMENT,
    GET_INVESTOR_INVESTMENTS_BY_STATUS_URL,
    GET_INVESTOR_INVESTMENTS_URL,
    GET_INVESTOR_INVESTMENT_DEAL_SHEET,
    GET_INVESTOR_INVESTMENT_DETAILS,
    GET_INVESTOR_INVESTMENT_OVERVIEW,
    GET_INVESTOR_INVESTMENT_SHEET_BY_DOCUMENT_TYPE,
    GET_OPPORTUNITY_INVESTMENTS_URL,
    GET_TRANCHE_PENDING_INVESTMENTS,
    GET_UPCOMING_REPAYMENTS,
    GET_USER_CONSENT_URL,
    MARK_USER_CONSENT_URL,
    POST_ACCEPT_INVESTMENT_RFQ,
    UPDATE_AMO_ORDER_PROMISED,
    UPDATE_FREECHARGE_PAYMENT_REQUEST_URL,
} from '@services/urls/investment';
import {
    CREATE_OPPORTUNITY_TRANCHE,
    UPDATE_OPPORTUNITY_TRANCHE,
} from './urls/opportunity';

export const createInvestment = (
    unitsPurchased,
    entityId,
    entityType,
    opportunityId,
    settlementType,
    stampDuty,
    isAmo,
    ctx = null
) => {
    let entity = {
        unitsPurchased,
        entityId,
        entityType,
        opportunityId,
        settlementType,
        stampDuty,
        isAmo
    };
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(
            CREATE_INVESTMENT_URL(),
            entity,
            ctx
        );
    }
    return SsrHttpService.postWithAuth(CREATE_INVESTMENT_URL(), entity, ctx);
};

export const getInvestmentByEntityOpportunity = (
    entityId,
    opportunityId,
    ctx = null
) => {
    const url = GET_INVESTMENT_BY_ENTITY_OPPORTUNITY_URL(
        entityId,
        opportunityId
    );
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getOpportunityInvestments = (id, page, limit, ctx = null) => {
    const url = GET_OPPORTUNITY_INVESTMENTS_URL(id, page, limit);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestorInvestments = (id, page, limit, ctx = null) => {
    const url = GET_INVESTOR_INVESTMENTS_URL(id, page, limit);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestorInvestmentsByStatus = (
    id,
    page,
    limit,
    status,
    column,
    sortOrder,
    ctx = null
) => {
    const url = GET_INVESTOR_INVESTMENTS_BY_STATUS_URL(
        id,
        page,
        limit,
        status,
        column,
        sortOrder
    );
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const markUserConsent = (
    entityType,
    entityId,
    type,
    jsonData = null,
    ctx = null
) => {
    const entity = { entityType, entityId, type, jsonData };
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(MARK_USER_CONSENT_URL(), entity);
    }
    return SsrHttpService.postWithAuth(MARK_USER_CONSENT_URL(), entity, ctx);
};

export const getConsent = (entityId, type, ctx = null) => {
    const url = GET_USER_CONSENT_URL(entityId, type);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const createConsent = (data, ctx = null) => {
    const url = CREATE_USER_CONSENT_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const createOfflineInvestment = ({
    entityId,
    tradeNumber,
    settlementDate,
    opportunityId,
    rfqNumber,
    settlementType,
    transactionId,
    ctx = null
}) => {
    const url = CREATE_OFFLINE_INVESTMENT_URL();
    let entity = {
        entityId,
        tradeNumber,
        settlementDate,
        opportunityId,
        rfqNumber,
        settlementType,
        transactionId,
    };
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, entity);
    }
    return SsrHttpService.postWithAuth(url, entity, ctx);
};

export const getTranchePendingInvestments = (
    opportunityId,
    trancheStatus,
    startInvestmentDate = '',
    endInvestmentDate = '',
    paymentMode = '',
    ctx = null
) => {
    const url = GET_TRANCHE_PENDING_INVESTMENTS(
        opportunityId,
        trancheStatus,
        startInvestmentDate,
        endInvestmentDate,
        paymentMode
    );

    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const CreateOpportunityTranche = (opportunityId, data, ctx = null) => {
    const url = CREATE_OPPORTUNITY_TRANCHE(opportunityId);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const UpdateOpportunityTranche = (opportunityId, data, ctx = null) => {
    const url = UPDATE_OPPORTUNITY_TRANCHE(opportunityId);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};

export const GetUpcomingRepayments = (ctx = null) => {
    const url = GET_UPCOMING_REPAYMENTS();
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestorInvestment = (investorId, investmentId, ctx = null) => {
    const url = GET_INVESTOR_INVESTMENT(investorId, investmentId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const UpdateFreechargePaymentRequest = (
    paymentHistoryId,
    ctx = null
) => {
    const url = UPDATE_FREECHARGE_PAYMENT_REQUEST_URL();
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, { paymentHistoryId });
    }
    return SsrHttpService.putWithAuth(url, { paymentHistoryId }, ctx);
};

export const RetryFreechargePayment = (paymentHistoryId, ctx = null) => {
    const url = FREECHARGE_PAYMENT_RETRY_URL();
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, { paymentHistoryId });
    }
    return SsrHttpService.putWithAuth(url, { paymentHistoryId });
};

export const downloadRealisedIRRDetails = (investmentId, ctx = null) => {
    const url = DOWNLOAD_INVESTMENT_CASHFLOWS_URL(investmentId);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, { investmentId });
    }
    return SsrHttpService.postWithAuth(url, { investmentId }, ctx);
};

export const downloadInvestmentBy = (investorId, ctx = null) => {
    const url = DOWNLOAD_INVESTMENT_URL(investorId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const downloadUpcomingRepaymentBy = (investorId, ctx = null) => {
    const url = DOWNLOAD_UPCOMING_REPAYMENT_URL(investorId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestorInvestmentOverview = (
    investorId,
    investmentId,
    ctx = null
) => {
    const url = GET_INVESTOR_INVESTMENT_OVERVIEW(investorId, investmentId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestorInvestmentDealSheet = (investmentId, ctx = null) => {
    const url = GET_INVESTOR_INVESTMENT_DEAL_SHEET(investmentId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestorInvestmentSheetByDocumentType = (investmentId, documentType, ctx = null) => {
    const url = GET_INVESTOR_INVESTMENT_SHEET_BY_DOCUMENT_TYPE(investmentId);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, documentType);
    }
    return SsrHttpService.postWithAuth(url,documentType, ctx);
};


export const acceptInvestmentRFQ = (
    investorId,
    investmentId,
    payload,
    ctx = null
) => {
    const url = POST_ACCEPT_INVESTMENT_RFQ(investorId, investmentId);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, payload);
    }
    return SsrHttpService.postWithAuth(url, payload, ctx);
};

export const getInvestorInvestmentDetails = (entityId, id, ctx = null) => {
    const url = GET_INVESTOR_INVESTMENT_DETAILS(entityId, id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestorNSEBankDetails = (investorId, ctx = null) => {
    const url = GET_INVESTOR_EXCHANGE_NSE_BANK_DETAILS(investorId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestorHasInvestments = (ctx = null) => {
    const url = GET_INVESTOR_HAS_INVESTMENTS();
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};


 

export const getInvestmentsOrdersByStatus = (
    id,
    page,
    status,
    ctx = null
) => {
    const url = GET_INVESTMENTS_ORDERS_BY_STATUS_URL(
        id,
        page,
        status
    );
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestmentsOrdersDetails = (
    investorId,
    tradeNumber,
    settlementDate,
    opportunityId,
    ctx = null
) => {
    const url = GET_INVESTMENTS_ORDERS_DETAILS_URL(
        investorId,
        tradeNumber,
        settlementDate,
        opportunityId
    );
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const amoOrderConfirmation = (investmentId, data = {}) => {
    const url = UPDATE_AMO_ORDER_PROMISED(investmentId);

    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
} 

export const getCashfreeEligibleBanks = () => {
    const url = CASHFREE_ELIGIBLE_BANKS();
    
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};