import React from 'react';

const RadioButton = ({
    changed,
    id,
    isSelected,
    isDisabled,
    label,
    value,
    register,
    registerId,
    radioHide = false,
    ...props
}) => {
    return (
        <div
            className={`mb-4 last:mb-0 md:mb-0 md:mr-6  min-h-[1.5rem] flex items-center ${isDisabled ? '' : 'cursor-pointer'} ${props.wrapperclass}`}
            onClick={changed}
        >
            {!radioHide && <input
                {...register(registerId)}
                gtmTag={props.gtmTag ? props.gtmTag : id}
                id={id}
                onChange={changed}
                value={value}
                type="radio"
                checked={isSelected}
                disabled={isDisabled}
                className={`w-6 h-6 border-gray-300 ${isDisabled ? '' : 'cursor-pointer'} bg-basicWhite focus:border-0 focus:outline-none text-primary-500 focus:ring-0 `}
            />}
            <label
                htmlFor={id}
                className={`${isDisabled ? '' : 'cursor-pointer'} ml-4 md:ml-2 text-basicBlack text-base font-medium ${props.labelClassName}`}
            >
                {label}
            </label>
        </div>
    );
};

export default RadioButton;
