import React from 'react';

const Badge = ({
    content,
    className,
    paddingClass = 'px-2 py-1',
    borderRadiusClass = 'rounded',
}) => {
    return (
        <div className={`${borderRadiusClass} ${paddingClass} ${className}`}>
            {content}
        </div>
    );
};

export default Badge;
