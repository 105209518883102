import { UrlParamsReplace } from './index';

export const CREATE_INVESTOR_URL = () => UrlParamsReplace('/investors');

export const CREATE_INVESTOR_BANK_DETAILS_URL = () =>
    UrlParamsReplace('/investor-bank-account-details');

export const CREATE_INVESTOR_DOCUMENT_URL = () =>
    UrlParamsReplace('/investor-documents');

export const UPDATE_NEW_EMAIL_URL = () =>
    UrlParamsReplace('/investors/update-email');

export const GET_EXISTING_INVESTOR_URL = (mobile) =>
    UrlParamsReplace('/existing-account?mobile=:mobile', { mobile });

export const GET_INVESTOR_URL = (id) =>
    UrlParamsReplace('/investors/:id', { id });

export const GET_INVESTOR_URL_V2 = (id) =>
    UrlParamsReplace('/v2/investor/:id', { id });

export const CREATE_PAYMENT_URL = () => UrlParamsReplace('/payments');

export const GET_PAYMENT_URL = (opportunityId, investorId) =>
    UrlParamsReplace(
        '/payments?opportunityId=:opportunityId&investorId=:investorId',
        { opportunityId, investorId }
    );
export const GET_PAYMENT_HISTORY_URL = (id, opportunityId) =>
    UrlParamsReplace('/payment-history/:id?opportunityId=:opportunityId', {
        id,
        opportunityId,
    });

export const EDIT_PANCARD_DETAILS_URL = (id) =>
    UrlParamsReplace('/investor-documents/pan/:id', { id });

export const EDIT_BANK_DETAILS_URL = (id) =>
    UrlParamsReplace('/admin/investor-bank-account-details/:id', { id });

export const GET_BANK_DETAILS_URL = (id, masked) =>
    UrlParamsReplace('/investor-bank-account-details/:id?masked=:masked', {
        id,
        masked,
    });

export const EDIT_ADDRESS_DETAILS_URL = (id) =>
    UrlParamsReplace('/investor-address-details/:id', { id });

export const MARK_INVESTOR_DOCUMENT_STATUS_URL = (id) =>
    UrlParamsReplace('/investor-documents/:id/mark-status', { id });

export const MARK_BANK_DETAILS_STATUS_URL = (id) =>
    UrlParamsReplace('/admin/investor-bank-account-details/mark-status/:id', {
        id,
    });

export const MARK_INVESTOR_DEMAT_DETAILS_STATUS_URL = (id) =>
    UrlParamsReplace('/admin/investor-demat-account-details/:id/mark-status', {
        id,
    });



export const GET_OPPORTUNITY_PAYMENT_SCHEDULE_URL = (opportunityId, status) =>
    UrlParamsReplace(
        '/opportunity-payment-schedule?opportunityId=:opportunityId&status=:status',
        { opportunityId, status }
    );

export const UPDATE_OPPORTUNITY_PAYMENT_SCHEDULE_BY_PAYMENT_ID_URL = (
    paymentId
) =>
    UrlParamsReplace('/opportunity-payment-schedule/:paymentId', {
        paymentId,
    });

export const BULK_UPLOAD_OPPORTUNITY_PAYMENT_SCHEDULE_FILE = () =>
    UrlParamsReplace('/opportunity-payment-schedule-file');

export const EDIT_INVESTOR_ADDRESS_DOCUMENT_URL = (id) =>
    UrlParamsReplace('/investor-documents/addressProof/:id', { id });

export const GET_CONFIGURATIONS_URL = (configurationType, entityType) =>
    UrlParamsReplace(
        '/configurations/:configurationType/entityTypes/:entityType',
        { configurationType, entityType }
    );

export const GET_INVESTORS_FOR_KYC_URL = (
    page,
    limit,
    searchText,
    searchPanText,
    kycStatus,
    nseWorkflowStatus
) =>
    UrlParamsReplace(
        '/investors-for-kyc?page=:page&limit=:limit&searchText=:searchText&searchPanText=:searchPanText&kycStatus=:kycStatus&nseWorkflowStatus=:nseWorkflowStatus',
        { page, limit, searchText, searchPanText, kycStatus, nseWorkflowStatus }
    );

export const ADD_OPPORTUNITY_PAYMENT_SCHEDULE_URL = () =>
    UrlParamsReplace('/opportunity-payment-schedule');

export const UPDATE_BULK_OPPORTUNITY_PAYMENT_SCHEDULE_URL = (opportunityId) =>
    UrlParamsReplace('/opportunities/:opportunityId/update-payment-schedule', {
        opportunityId,
    });

export const CREATE_USER_PERMISSION_URL = () =>
    UrlParamsReplace('/user-permissions');

export const DOWNLOAD_INVESTORS_FOR_OPPORTUNITY = (opportunityId) =>
    UrlParamsReplace('/opportunities/:opportunityId/investors', {
        opportunityId,
    });

export const GET_INVESTOR_OPPORTUNITY_DOCUMENT_BY_INVESTOR_OPPORTYNITY_ID_URL =
    (investorId, opportunityId, investmentId, type) =>
        UrlParamsReplace(
            '/investors/:investorId/investor-opportunity-documents?opportunityId=:opportunityId&investmentId=:investmentId&type=:type',
            { investorId, opportunityId, investmentId, type }
        );

export const GET_INVESTORS_URL = (page, limit, searchText, searchPanText) =>
    UrlParamsReplace(
        '/investors?page=:page&limit=:limit&searchText=:searchText&searchPanText=:searchPanText',
        {
            page,
            limit,
            searchText,
            searchPanText,
        }
    );


export const ADMIN_LIST_PENDING_INVESTOR_DEMATS = (page, limit, searchText, searchPanText) =>
    UrlParamsReplace('/admin/investors/pending-demats?page=:page&limit=:limit&searchText=:searchText&searchPanText=:searchPanText',
        {
            page,
            limit,
            searchText,
            searchPanText,
        })


export const ADMIN_LIST_MULTIPLE_BANK_MANUAL_VERIFICATION = (page, limit, personalDetailsSearch, panSearch) =>
    UrlParamsReplace('/admin/investor-bank-account-details/multiple/manual-verification-required?page=:page&limit=:limit&panSearch=:panSearch&personalDetailsSearch=:personalDetailsSearch',
        {
            page,
            limit,
            personalDetailsSearch,
            panSearch,
        })


export const GET_DROPPED_OFF_INVESTORS_URL = (opportunityId, page, limit) =>
    UrlParamsReplace(
        '/opportunities/:opportunityId/droppedOffInvestors?page=:page&limit=:limit',
        {
            opportunityId,
            page,
            limit,
        }
    );
export const UPDATE_ISNRI_URL = (investorId) =>
    UrlParamsReplace('/investors/:investorId', { investorId });

export const CREATE_OR_UPDATE_INVESTOR_PROFILE_DETAILS = (investorId) =>
    UrlParamsReplace('/investors/:investorId/investor-profile-details', {
        investorId,
    });

export const UPDATE_INVESTOR_INVITATION_BANK_DETAILS_URL = (bankDetailsId) =>
    UrlParamsReplace('/invitation-bank-account-details/:bankDetailsId', {
        bankDetailsId,
    });

export const UPDATE_MOBILE_URL = () =>
    UrlParamsReplace('/investors/update-mobile');

export const CREATE_PAN_INVESTOR_DOCUMENT_URL = () =>
    UrlParamsReplace('/investor-documents/pan');

export const CREATE_PAN_INVESTOR_DOCUMENT_V2_URL = () =>
    UrlParamsReplace('/v2/investor-documents/pan');

export const CREATE_ADDRESS_INVESTOR_DOCUMENT_URL = () =>
    UrlParamsReplace('/investor-documents/address');

export const CREATE_ADDRESS_INVESTOR_DOCUMENT_FROM_ADMIN_URL = (investorId) =>
    UrlParamsReplace('/investor-documents/address/:investorId', { investorId });

export const GET_INVESTOR_DOCUMENT_URL = (investorId, type) =>
    UrlParamsReplace(
        '/investors/:investorId/investor-opportunity-documents?type=:type',
        { investorId, type }
    );

export const GET_INVESTOR_RELATIONSHIP_MANAGER_DETAILS = (entityId) =>
    UrlParamsReplace('/entity/:entityId/relationship-manager', {
        entityId,
    });

export const CREATE_INVESTOR_WALLET_URL = () => UrlParamsReplace('/wallets');

export const ADD_WALLET_AMOUNT_URL = () => UrlParamsReplace('/wallets');

export const GET_INVESTOR_KYC_STATUS = (investorId) =>
    UrlParamsReplace('/investor/:investorId/kyc-status', {
        investorId,
    });
export const GET_INVESTOR_DEMAT_URL = (investorId) =>
    UrlParamsReplace('/investor/:investorId/demat', {
        investorId,
    });
export const GET_DEPOSITORY_PARTICIPANTS_DEMAT_URL = (investorId) =>
    UrlParamsReplace('/depository-participants/demat-account/:investorId', {
        investorId,
    });

export const CREATE_INVESTOR_DEMAT = () => UrlParamsReplace('/investor-demat');

export const UPDATE_INVESTOR_DEMAT = () => UrlParamsReplace('/admin/investor-demat');


export const GET_INVESTORS_ESIGN_KYC_FILENAMES_URL = (investorId) =>
    UrlParamsReplace('/investors/:investorId/esign-kyc-fileNames', {
        investorId,
    });

export const GET_INVESTORS_ESIGN_KYC_URL = (investorId, type) =>
    UrlParamsReplace('/investors/:investorId/esign-kyc?type=:type', {
        investorId,
        type,
    });

export const UPDATE_INVESTOR_EXCHANGE_ACCOUNT = (investorId) =>
    UrlParamsReplace('/investor/:investorId/account-number', {
        investorId,
    });

export const UPDATE_INVESTOR_OVERVIEW_COMPLETED = (investorId) =>
    UrlParamsReplace('/investors/:investorId/update-overview-completed', {
        investorId,
    });

export const UPDATE_MIGRATED_INVESTOR_DEMAT_DETAILS = (investorId) =>
    UrlParamsReplace('/migrated-investor-demat-details/:investorId', {
        investorId,
    });

export const UPDATE_MIGRATED_INVESTOR_BANK_DETAILS = (investorId) =>
    UrlParamsReplace('/investor-bank-account-details/migrated/:investorId', {
        investorId,
    });

export const DOWNLOAD_PENDING_INVESTOR_DEMATS_BY_ADMIN = (investorId) =>
    UrlParamsReplace('/admin/investors/pending-demats/download');


export const BULK_UPLOAD_PENDING_INVESTOR_DEMATS_BY_ADMIN = (investorId) =>
    UrlParamsReplace('/admin/investors/verified-demats/upload');

export const UPDATE_VERIFY_INVESTOR_DEMAT_BY_ADMIN = (id) =>
    UrlParamsReplace('/admin/investor-demat/verify/:id', {
        id,
    });

export const CREATE_INVESTOR_DECLARATION = () => UrlParamsReplace('/investor-declaration');

export const GET_INVESTOR_ESIGN_FORM_URL = (investorId) =>
    UrlParamsReplace('/investors/:investorId/generate-esign-kyc-file', { investorId });

export const GET_INVESTOR_KYC_NUDGE = (investorId) =>
    UrlParamsReplace('/investor/:investorId/kyc-nudge', { investorId });

export const POST_INVESTOR_PAN_HVOCR = () => UrlParamsReplace('/investors/pan/hvOcr');

export const INVESTOR_NOMINEE_URL = () => UrlParamsReplace('/investor-nominee');

export const GET_INVESTOR_NOMINEE = (investorId) => UrlParamsReplace('/investor-nominee-details/:investorId', { investorId });

export const CREATE_INVESTOR_PAN_VALIDATION = () => UrlParamsReplace('/investor-pan-validation');

export const GET_INVESTOR_PAN_VALIDATION = () => UrlParamsReplace('/investor-pan-validation');

export const GET_INVESTOR_AMO_NUDGE = (id) => UrlParamsReplace('/investor/:id/amo-nudge-banner', {
    id
});

export const GET_BULK_OPPORTUNITY_PAYMENT_SCHEDULES_URL = (opportunityId) =>
    UrlParamsReplace('/opportunity-payment-schedule/:opportuntyId/schedules', {
        opportunityId,
    });