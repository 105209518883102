export const TERMS_AND_CONDITIONS_ROUTE = '/terms-and-condition';

export const PRIVACY_POLICY_ROUTE = '/privacy-policy';

export const DISCLAIMER_ROUTE = '/disclaimer';

export const CONTACT_US_ROUTE = '/contact-us';

export const CB_PRODUCT_ROUTE = '/products/corporate-bond';

export const SGB_PRODUCT_ROUTE = '/products/sovereign-gold-bonds';

export const TB_PRODUCT_ROUTE = '/products/treasury-bills';

export const GSEC_SDL_PRODUCT_ROUTE = '/products/g-secs-sdls';

export const NRI_DISCLAIMER_ROUTE = '/nri-disclaimer';

export const GRIEVANCES_ROUTE = '/grievance-redressal';

export const INVESTOR_DISCLAIMER_ROUTE = '/investment-disclaimer';

export const SDIs_PRODUCT_ROUTE = '/products/sdi';
