export const helperClasses = {
    '.flex-center': {
        '@apply inline-flex items-center justify-center': {},
    },
    '.flex-between': {
        '@apply inline-flex items-center justify-between': {},
    },
    '.flex-start': {
        '@apply inline-flex items-center justify-start': {},
    },
};
