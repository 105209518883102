import { UrlParamsReplace } from '@services/urls/index';

export const GET_HOME_PAGE_CONFIGURATIONS_URL = () =>
    UrlParamsReplace('/openConfigurations/General/entityTypes/HomePageBanner');

export const GET_CONFIGURATIONS_URL = (configurationType, entityType) =>
    UrlParamsReplace(
        '/configurations/:configurationType/entityTypes/:entityType',
        {
            configurationType,
            entityType,
        }
    );

export const GET_OPEN_CONFIGURATIONS_URL = (entityType) =>
    UrlParamsReplace('/configurations/entityTypes/:entityType', {
        entityType,
    });
