const Checkbox = ({
    register,
    id,
    type,
    label,
    error,
    labelClass,
    wrapperclass,
    roundedClass = 'rounded-lg',
    onClick,
    gtmtag,
    ...inputProps
}) => {
    return (
        <>
            {label && (
                <div
                    className={`flex justify-start items-center text-left text-gray-900 py-4 ${wrapperclass}`}
                >
                    <input
                        {...register(id)}
                        id={id}
                        {...inputProps}
                        type="checkbox"
                        onClick={onClick}
                        gtmtag={gtmtag ? gtmtag : id}
                        className={`${error
                            ? 'border-semantic-error-base'
                            : 'border-gray-300'
                            } border-2 ${inputProps.width ? inputProps.width : 'w-full'
                            } ${inputProps.height ? inputProps.height : 'h-12'
                            }  mx-4 ${roundedClass}  box-border self-center ${inputProps.className
                            } hover:!border-primary-500 focus:ring-0 checked:bg-primary-500  checked:hover:bg-primary-500 !focus: checked:focus:bg-primary-500 outline-none focus:outline-none`}
                    />
                    <label className={`text-gray-500 ${labelClass}`}>
                        {label}
                    </label>
                </div>
            )}

            {error && (
                <div className="self-start text-left text-semantic-error-base p5-regular">
                    {error.message}
                </div>
            )}
        </>
    );
};

export default Checkbox;
