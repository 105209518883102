const EntityType = Object.freeze({
    INDIVIDUAL: 'Individual',
    NRI: 'NRI',
    USER: 'User',
    INVESTOR: 'Investor',
});

const PoliticalExposure = Object.freeze({
    YES: 'Yes',
    NO: 'No',
    PEP_RELATED: 'PEPRelated',
});

export { EntityType, PoliticalExposure };
