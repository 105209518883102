const plugin = require('tailwindcss/plugin');
const defaultTheme = require('tailwindcss/defaultTheme');
const colorConstants = require('./obp-ui/helpers/color-constants');
const { typography } = require('./obp-ui/helpers/designSystem/typography');
const { helperClasses } = require('./obp-ui/helpers/designSystem/utilities');
const defaultTailwindColors = require('./obp-ui/helpers/tailwind-default-colors');

const withMT = require('@material-tailwind/react/utils/withMT');

module.exports = withMT({
    content: [
        './pages/*.js',
        './pages/**/*.js',
        './pages/**/**/*.js',
        './pages/**/**/**/*.js',
        './pages/**/**/**/**/*.js',
        './atoms/*.js',
        './molecules/*.js',
        './molecules/**/*.js',
        './organisms/*.js',
        './organisms/**/*.js',
        './templates/*.js',
        './templates/**/*.js',
        './components/**/*.{js,jsx}',
        './components/**/**/*.{js,jsx}',
        './components/**/**/**/*.{js,jsx}',
        './components/**/**/**/**/*.{js,jsx}',
        './obp-ui/**/*.{js,jsx}',
        './node_modules/@material-tailwind/react/components/**/*.{js,ts,jsx,tsx}',
        './node_modules/@material-tailwind/react/theme/components/**/*.{js,ts,jsx,tsx}',
    ],
    options: {
        whitelist: ['.grid', '.grid-cols-3', '.gap-x-5'],
        safelist: [
            {
                variants: ['text-5xl', 'sm', 'lg', 'xl', 'md', '.w-1010'],
            },
        ],
    },
    theme: {
        letterSpacing: {
            'tighter-heading': '-1.8px',
            ...defaultTheme.letterSpacing,
        },
        colors: {
            /* IMPORTANT: New Colors from design system should be 
            added to obp-ui/helpers/color-constants.js */
            ...defaultTailwindColors, // used in Admin (will remove when we move to new design colors for admin)
            ...colorConstants,
            gray: {
                ...colorConstants.gray,
                60: '#69747A', // used in Admin
                950: '#707683', // used in Admin
            },
            yellow: {
                ...colorConstants.yellow,
                300: '#fde047', // used in admin
                400: '#facc15', // used in admin
                600: '#ca8a04', // used in admin
            },
            darkBlack: '#222428', //used in admin
            'gray-medium-shade': '#808289', // used in admin
            'gray-light-shade': '#D7D8DA', // used in admin
            'athens-gray': '#E7E9F0', // used in Admin
        },
        extend: {
            gridTemplateColumns: {
                13: 'repeat(13,minmax(0,1fr))',
                14: 'repeat(14,minmax(0,1fr))',
                16: 'repeat(16,minmax(0,1fr))',
                18: 'repeat(18,minmax(0,1fr))',
                20: 'repeat(20,minmax(0,1fr))',
                21: 'repeat(21,minmax(0,1fr))',
            },
            keyframes: {
                fade: {
                    from: {
                        opacity: 0,
                        transform: 'translate3d(0, -1rem, 0)',
                    },
                    to: {
                        opacity: 1,
                        transform: 'translate3d(0,0, 0)',
                    },
                },
                'slow-bounce': {
                    '0%, 100%': { transform: 'translateY(-25%)' },
                    '50%': { transform: 'none' },
                },
                'bouncing-dots': {
                    '0%, 100%': { opacity: '1', transform: 'translateY(0)' },
                    '50%': { opacity: '0.1', transform: 'translateY(-6px)' },
                },
            },
            animation: {
                'fade-in': 'fade 500ms linear ',
                'slow-bounce': 'slow-bounce 1.5s infinite;',
                bounce1: 'bouncing-dots 1.5s infinite',
                bounce2: 'bouncing-dots 1.5s infinite 0.4s',
                bounce3: 'bouncing-dots 1.5s infinite 0.6s',
            },

            lineHeight: {
                tighter: 1.15,
                32: '8rem',
            },
            screens: {
                minScreen: '300px',
                mobile: '320px',
                xs: { min: '425px', max: '640px' },
                'desktop-md': '1440px',
                'h-medium': { raw: '(max-height: 800px)' },
                'h-small': { raw: '(max-height: 700px)' },
                'h-x-small': { raw: '(max-height: 600px)' },
                maxScreen: '1440px',
                ...defaultTheme.screens,
            },
            zIndex: {
                '-1': '-1',
                100: '100',
                999: 999,
            },
            listStyleType: {
                roman: 'lower-roman',
                alpha: 'lower-alpha',
            },
            grayscale: {
                100: '100%',
            },
            dropShadow: {
                xl: '0 20px 13px rgba(117, 117, 247, 0.2)',

                '3xl': '0 45px 45px rgba(117, 117, 247, 0.2)',
            },
            inset: (theme) => ({
                '1/5': '20%',
                '1/10': '10%',
                '1/20': '4.3%',
                10.5: '2.625rem',
                8.5: '2.125rem',
                24.5: '6.3rem',
                200: '50rem',
            }),
            margin: (theme) => ({
                22: '5.5rem',
                15: '3.75rem',
                13: '2.188rem',
                15.5: '4.688rem',
            }),
            padding: (theme) => ({
                'screen-90': '80vh',
                'screen/1.3': '75vh',
                'screen/2': '50vh',
                'screen/3': 'calc(100vh / 3)',
                'screen/4': 'calc(100vh / 4)',
                'screen/5': 'calc(100vh / 5)',
                4.5: '1.125rem',
                '52px': '3.25rem',
                18: '4.5rem',
                53: '13.25rem',
                22: '5.5rem',
                '18px': '1.125rem',
                '34px': '2.125rem',
                17: '4.25rem',
                19: '4.75rem',
                26: '6.5rem',
                14: '3.625rem',
                34: '8.5rem',
                46: '11.813rem',
                62: '15.875rem',
                18.5: '4.625rem',
                114.5: '28.625rem',
            }),
            height: (theme) => ({
                '2px': '2px',
                18: '4.5rem',
                22: '5.5rem',
                75: '18.75rem',
                200: '50rem',
                127: '508px',
                138: '552px',
                121: '30.25rem',
                107: '26.75rem',
                '95%': '95%',
                85: '21.25rem',
                85: '21.25rem',
                85: '21.25rem',
                '92vh': '92vh',
            }),
            borderWidth: (theme) => ({
                1: '1px',
            }),
            borderRadius: (theme) => ({
                '10px': '0.625rem',
                '7px': '0.438rem',
                '20px': '1.125rem',
                '32px': '2rem',
                '40px': '2.5rem',
                '80px': '5rem',
            }),
            maxWidth: {
                60: '12rem',
                70: '17.5rem',
                316: '79rem',
                745: '745px',
                md: '768px',
                1024: '1024px',
            },
            boxShadow: {
                0.5: '0px 2px 8px rgba(0, 0, 0, 0.08)',
                sm: '10px 17px 34px rgba(22, 28, 40, 0.17)',
                xs: '0 4px 14px rgba(0,0,0,0.15)',
                'home-page-banner-card': '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
                'home-page-banner-card-2':
                    '0px 15px 30px 0px rgba(0, 0, 0, 0.10)',
                button: '10px 17px 34px 0px rgba(22, 28, 40, 0.17)',
                'video-banner-shadow': '0px 5px 30px 10px rgba(0, 0, 0, 0.25)',
                'bond-fundamental-card-shadow':
                    '0px 4px 14px 0px rgba(11, 7, 110, 0.04)',
                'custom-sm': '0px 6px 18px 0px rgba(11, 7, 110, 0.07),',
                'amo-shadow': '0px 0px 16px 0px rgba(255, 159, 0, 0.12)',
                'success-icon': '0px 9.6px 28.8px 0px rgba(11, 7, 110, 0.07)',
                'top-invest-now-mobile':
                    '0px -4px 14px 0px rgba(11, 7, 110, 0.10)',
                '1dp—elevation':
                    '0px 0px 1px 0px rgba(12, 26, 75, 0.20), 0px 1px 3px 0px rgba(50, 50, 71, 0.10);',
                'home-page-banner-card': '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
                'lg-custom': '0px 13px 40px 0px rgba(11, 7, 110, 0.10)',
                'card': '0px 14px 34px 0px rgba(0, 0, 0, 0.05)',
                'payment-status-footer': '0px -4px 8px 0px rgba(0, 0, 0, 0.04)',
                'empty-state': '0px 12px 24px 0px rgba(11, 7, 110, 0.04)',
            },
            fontFamily: {
                inter: ['Inter', 'sans-serif'],
                Neue: ['Bebas Neue', 'cursive'],
                montserrat: ['Montserrat', 'sans-serif'],
                bahnschrift: ['Bahnschrift', 'sans-serif'],
            },
            spacing: {
                360: '5.625rem',
                122: '30.625rem',
            },
            fontWeight: (theme) => ({
                normal: '400',
                aboveNormal: '450',
            }),
            content: {
                rupeeSymbol: '₹',
            },
            fontSize: (theme) => ({
                small: '0.6875rem', //11px,
                'title:size': [33, 36],
                '10px': ['0.65rem'],
                '18px': '0.938rem',
                '28px': '1.75rem',
                '32px': '2rem', //32px
                '3xl': '2rem', //32px
                '4xl': '2.5rem', //40px
                '5xl': '3rem', //48px
                '6xl': '3.5rem', //56px
                '7xl': '4rem', //64px
                '8xl': '4.5rem', //72px
                '40px': ['2.5rem', 1.5],
                '47px': ['47px', '60px'],
                '77px': ['70px', '78px'],
            }),
            container: {
                padding: {
                    xl: '2rem',
                },
            },
            width: {
                18: '4.5rem',
                22: '5.5rem',
                100: '25rem',
                250: '1000',
                333: '83rem',
                120: '30rem',
                140: '35rem',
                316: '79rem',
                334: '20.875rem',
                748: '46.75rem',
                1010: '63.125rem',
            },
            minWidth: {
                50: '12.5rem',
                55: '13.75rem',
                85.75: '21.438rem',
                150: '37.5rem',
            },
            minHeight: {
                12: '3rem',
                25: '6.25rem',
                400: '400px',
            },
            maxHeight: {
                section: '80vh',
            },
            backgroundImage: {
                'opportunity-sticky-actions-sm':
                    'linear-gradient(180deg, transparent 23%, rgba(247, 247, 254) 13%)',
                'opportunity-sticky-actions':
                    'linear-gradient(180deg, transparent 50%, rgba(247, 247, 254) 50%)',
                'jiraaf-light-gray-bg-left':
                    'url(/images/CreditEvaluationProcess/jiraaf-light-gray-bg-left.png)',
                'jiraaf-light-gray-bg-right':
                    'url(/images/CreditEvaluationProcess/jiraaf-light-gray-bg-right.png)',
                'jiraaf-light-gray-bg-left-mobile':
                    'url(/images/CreditEvaluationProcess/jiraaf-light-gray-bg-left-mobile.png)',
                'green-gradient':
                    'linear-gradient(265deg, #69B21B 4.15%, #1D664A 95.85%)',
                'yellow-gradient':
                    'linear-gradient(90deg, #FFEACB 0%, #FFBA35 97.73%)',
                'kyc-bg':
                    'linear-gradient(90deg, #289A3F 0%, #289A3F 50%, #D2F3D5 50%, #D2F3D5 100%)',
                'welcome-asset-card-mobile':
                    'linear-gradient(270deg, #FEF2DB 0%, #FFF9EF 100%)',
                'welcome-asset-card':
                    'linear-gradient(90deg, #FFF9EF 0%, #FFF 100%)',
                'welcome-asset-card-hover':
                    'linear-gradient(90deg, #FFF 0%, #FEF2DB 100%)',
                'blue-gradient': 'linear-gradient(267deg, #0054AD 6.77%, #003975 99.57%)',
                'refer-and-earn-steps': 'linear-gradient(90deg, #FFEACB 0%, #FFBA35 97.73%)',
                'blue-brand': "linear-gradient(267deg, #0054AD 6.77%, #003975 99.57%)",
                'festive-opportunity-card-border-gradient': 'linear-gradient(143deg, #D7BC5F -1.67%, #FFF2C6 23.45%, #D9AF1B 48.05%, #FFE99B 72.65%, #B49528 98.82%)',
                'festive-opportunity-card-irr': 'linear-gradient(270deg, #FFF5E3 0%, #FFF 100%)',
                'festive-gold-gradient': 'linear-gradient(92deg, #F3E2C2 0%, #FDF5DF 48.21%, #F3E2C2 96.02%)'
            },
            backgroundPosition: {
                'right-50': '50%',
                'right-68': '68%',
            },
            backgroundSize: {
                '135%': '135%',
                '160%': '160%',
                '300%': '300%',
            },
        },
    },
    plugins: [
        require('tailwindcss-rtl'),
        require('@tailwindcss/forms'),
        plugin(({ addUtilities }) => {
            addUtilities({ ...typography, ...helperClasses });
        }),
    ],
});
