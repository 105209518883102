const ApiUrl = process.env.NEXT_PUBLIC_API_URL;

export const UrlParamsReplace = (url, params = {}) => {
    let urlWithPrefix = `${ApiUrl}${url}`;
    if (params) {
        Object.keys(params).forEach((key) => {
            urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]);
        });
    }
    return urlWithPrefix;
};

export const getUrlFromMultipleQueryParams = (url, queryParams = null) => {
    if (queryParams) {
        const queryParamKeys = Object.keys(queryParams).filter(
            (key) => queryParams[key] !== ''
        );
        if (queryParamKeys.length > 0) {
            const queryParamsString = queryParamKeys
                .map((key) =>
                    typeof queryParams[key] === 'string'
                        ? queryParams[key]
                              .split(',')
                              .map(
                                  (value) =>
                                      `${key}=${encodeURIComponent(value)}`
                              )
                              .join('&')
                        : `${key}=${encodeURIComponent(queryParams[key])}`
                )
                .join('&');
            return `${ApiUrl}${url}?${queryParamsString}`;
        }
    }
    return `${ApiUrl}${url}`;
};
