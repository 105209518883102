import { create } from 'zustand';

import useInvestmentStore from './useInvestmentStore';
import useAuthStore from './useAuthStore';

import { kycNavItems } from '@components/KycFlow/utils/helpers';
import {
    getCurrentTab,
    getUpdatedKycNavItems,
} from '@components/Layout/utils/kycLayoutHelper';
import { isIndividual } from '@helpers/utils/investor-utils';

import KycStatusEnums from '@helpers/kyc-status';
import nseStatusEnums from '@helpers/nse-status';

import {
    getInvestorKycNudge,
    getInvestorKycStatus,
} from '@services/investors.service';
import { investorService } from '@services/middlewares/investor.middleware';
import { getNseEligibleBanks } from '@services/nse-rfqs.service';
import { getAuth, setAuth } from '@services/identity.service';
import routes from '@helpers/routes';

const initialState = {
    tabToShow: null,
    loading: true,
    kycStatus: null,
    kycNavItems: kycNavItems,
    investorKycStatus: null,
    tabRoute: null,
    entityId: null,
    accountType: null,
    nseRegistrationStatus: null,
    overviewCompleted: false,
    isMigrated: null,
    investor: null,
    isSignUpTC: null,
    showKycBanner: null,
    isUserMigrated: null,
    showNseBeneficiaryBanner: null,
    user: null,
    kycFlowStatus: null,
    isLoadingKycNudge: false,
    canDownloadSignedDocument: null,
    isNsePending: null,
    isDematVerified: null,
    eligibleBanks: [],
};

const useKycStore = create((set, get) => ({
    ...initialState,
    investor: useAuthStore.getState().investor || {},
    reset: () => {
        set({...initialState})
    },
    setLoading: (isLoading) => set({ loading: isLoading }),
    setInvestor: (investor) =>
        set((state) => ({ investor: { ...state.investor, ...investor } })),

    nextStep: async (forceFetch = true) => {
        get().setLoading(true);
        const auth = getAuth();

        const accountType = auth?.accountType;
        set({
            accountType: accountType || null,
            entityId: auth?.id,
            isMigrated: auth?.isMigrated,
            overviewCompleted: auth?.overviewCompleted,
            isSignUpTC: auth?.isSignUpTC,
            isUserMigrated: auth?.isUserMigrated,
        });

        if (isIndividual(accountType)) {
            await handleIndividualAccount(auth, forceFetch);
        } else {
            handleNonIndividualAccount(auth);
        }
        get().setLoading(false);
        window.scrollTo(0, 0);
    },

    getKycNudge: async () => {
        const { investor } = useAuthStore.getState() || {};
        get().setLoading(true);

        if (investor?.id) {
            await fetchKycNudgeData(investor);
        } else {
            set({
                showKycBanner: true,
                loading: false,
                kycStatus: null,
                nseRegistrationStatus: null,
                isNsePending: null,
                isDematVerified: null,
            });
        }
        get().setLoading(false);
    },

    getNseSupportedBanks: async () => {
        const response = await getNseEligibleBanks();
        if (response?.status) {
            const sortedBanks = response?.entity?.sort((a, b) =>
                a.bankName.localeCompare(b.bankName)
            );
            set({ eligibleBanks: sortedBanks || [] });
        }
    },

    initialize: async (forceFetch) => {
        try {
                await get().nextStep(forceFetch);
                await get().getKycNudge();
        } catch (error) {
            console.error('Error during initialization:', error);
        }
    },

    setKycBanners: async () => {
        await get().getKycNudge();
        const { showAddBeneficiaryPrompt } =
            useAuthStore.getState().investor || {};
        set({ showNseBeneficiaryBanner: showAddBeneficiaryPrompt });
    },
}));

const handleIndividualAccount = async (auth, forceFetch) => {
    const investorResponse = await investorService.get(auth.id, forceFetch);
    if (investorResponse) {
        setAuth({ ...auth, ...investorResponse });
        useKycStore.setState({
            kycStatus: investorResponse?.kycStatus,
            investor: investorResponse,
            nseRegistrationStatus: investorResponse?.nseWorkflowStatus,
            loading: false,
            overviewCompleted: investorResponse?.overviewCompleted,
            isMigrated: investorResponse?.isMigrated,
            showNseBeneficiaryBanner:
                investorResponse?.showAddBeneficiaryPrompt,
        });

        try {
            const response = await getInvestorKycStatus(auth.id);
            if (response?.status) {
                await updateKycStatus(response);
            }
        } catch (error) {
            console.error('ERROR: Fetching Kyc Status', error);
        }
    }
};

const handleNonIndividualAccount = (auth) => {
    const currentTab = getCurrentTab(kycNavItems);
    useKycStore.setState({
        tabToShow: currentTab,
        tabRoute: currentTab.link,
        loading: false,
        kycStatus: KycStatusEnums.PENDING,
        kycNavItems: kycNavItems,
        investor: null,
        isMigrated: auth?.isMigrated,
        overviewCompleted: auth?.overviewCompleted,
        isSignUpTC: auth?.isSignUpTC,
        showNseBeneficiaryBanner: false,
    });
};

const fetchKycNudgeData = async (investor) => {
    const kycNudgeResponse = await getInvestorKycNudge(investor?.id);
    const kycFlowStatus = await getInvestorKycStatus(investor.id);

    const { result: updatedKycNavItems, status } = await getUpdatedKycNavItems(
        kycFlowStatus.entity,
        kycNavItems
    );
    const currentTab = getCurrentTab(updatedKycNavItems, status);
    let kycSignedDocuments =
        !(kycFlowStatus?.entity.esign === 'Pending') ?? false;

    if (currentTab) {
        useKycStore.setState({
            tabToShow: currentTab,
            kycNavItems: updatedKycNavItems,
            tabRoute: currentTab.link,
            loading: false,
            kycFlowStatus: kycFlowStatus.entity,
            investor
        });
    } else {
        useKycStore.setState({
            loading: false,
            tabToShow: currentTab,
            kycNavItems: updatedKycNavItems,
            kycFlowStatus: kycFlowStatus.entity,
            tabRoute: routes.kyc.kycStatus,
            investor
        });
    }

    useKycStore.setState({
        showKycBanner: kycNudgeResponse?.entity?.showKycNudge || false,
        isNsePending:
            kycNudgeResponse?.entity?.nseKycStatus !== nseStatusEnums.APPROVED,
        isDematVerified: !!investor?.investorDemat?.dematVerifiedAt,
        loading: false,
        kycFlowStatus: kycFlowStatus?.entity || null,
        kycStatus: kycNudgeResponse?.entity?.kycStatus || null,
        nseRegistrationStatus: kycNudgeResponse?.entity?.nseKycStatus || null,
        canDownloadSignedDocument: kycSignedDocuments,
        showNseBeneficiaryBanner: investor?.showAddBeneficiaryPrompt,
        investor
    });
};

const updateKycStatus = async (response) => {
    const data = response.entity;
    const { result: updatedKycNavItems, status } = await getUpdatedKycNavItems(
        data,
        kycNavItems
    );

    const currentTab = getCurrentTab(updatedKycNavItems, status);

    useKycStore.setState({
        tabToShow: currentTab,
        kycNavItems: updatedKycNavItems,
        tabRoute: currentTab ? currentTab.link : routes.kyc.kycStatus,
        loading: false,
        kycFlowStatus: data,
    });
};

export default useKycStore;
