import { UrlParamsReplace } from './index';

export const ADMIN_LOGIN_URL = () => UrlParamsReplace('/v2/admin-session');

export const USER_LOGIN_URL = () => UrlParamsReplace('/user-sessions');

export const SEND_OTP_URL = () => UrlParamsReplace('/otp/send');

export const VERIFY_OTP_URL = () => UrlParamsReplace('/otp/verify');

export const GET_USER_WHATAPP_STATUS = (mobile) => {
    return UrlParamsReplace('/investor/whatsapp-status?mobile=:mobile', {
        mobile,
    });
};

export const REFRESH_SESSION = () => {
    return UrlParamsReplace('/refresh-sessions');
}
