module.exports = {
    //moengage specific events
    LOGIN_SUCCESS: "login_success",
    LOGOUT_SUCCESS: "logout_success",
    DEMAT_ADDED: "demat_added",
    QUESTIONNAIRE_SUBMIT: "questionnaire_submit",

    //web only moengage specific events
    SIGNUP_SUCCESS: "signup_success",
    KYC_STARTED: "kyc_started",

    //clicks
    CLICKED_OPP_DETAILS_INVEST_NOW: "clicked_opp_details_invest_now",
    CLICKED_OPP_DETAILS_REPAYMENT_SCH: "clicked_opp_details_repayment_sch",
    CLICKED_INV_OVERVIEW_CONTINUE_TO_PAYMENT: "clicked_inv_overview_continue_to_payment",
    CLICKED_INV_OVERVIEW_CONTINUE_TO_AMO: "clicked_inv_overview_continue_to_amo",
    CLICKED_INV_OVERVIEW_TERMS: "clicked_inv_overview_terms",
    CLICKED_INV_OVERVIEW_DOWNLOAD_ORDER_SHEET: "clicked_inv_overview_download_order_sheet",
    CLICKED_PAYMENT_PAY_SECURELY: "clicked_payment_pay_securely",
    CLICKED_PAYMENT_FAILED_RETRY: "clicked_payment_failed_retry",

    //Page views
    VIEWED_WELCOME_BACK_PAGE: "viewed_welcome_back_page",
    VIEWED_WELCOME_PAGE: "viewed_welcome_page",
    VIEWED_QUESTIONNAIRE_PAGE: "viewed_questionnaire_page",
    VIEWED_PROFILE_PAGE: "viewed_profile_page",
    VIEWED_PERSONAL_DETAILS_PAGE: "viewed_personal_details_page",
    VIEWED_ADDRESS_PAGE: "viewed_address_page",
    VIEWED_DEMAT_ACCOUNT_PAGE: "viewed_demat_account_page",
    VIEWED_BANK_ACCOUNT_PAGE: "viewed_bank_account_page",
    VIEWED_NOMINEE_PAGE: "viewed_nominee_page",
    VIEWED_OPPORTUNITY_LISTING_PAGE: "viewed_opportunity_listing_page",
    VIEWED_OPPORTUNITY_DETAILS_PAGE: "viewed_opportunity_details_page",
    VIEWED_LOGIN_PAGE: "viewed_login_page",
    VIEWED_INVESTMENT_LISTING_PAGE: "viewed_investment_listing_page",
    VIEWED_INVESTMENT_DETAILS_PAGE: "viewed_investment_details_page",
    VIEWED_PENDING_ORDER_PAGE: "viewed_pending_order_page",
    VIEWED_DASHBOARD_PAGE: "viewed_dashboard_page",
    VIEWED_FUND_DECLARATION_PAGE: "viewed_fund_declaration_page",
    VIEWED_INVESTMENT_DISCLAIMER_PAGE: "viewed_investment_disclaimer_page",
    VIEWED_INVESTMENT_TERMS_AND_CONDITION_PAGE: "viewed_investment_terms_and_condition_page",
    VIEWED_INVESTMENT_OVERVIEW_PAGE: "viewed_investment_overview_page",
    VIEWED_SIGN_AGREEMENT_PAGE: "viewed_sign_agreement_page",
    VIEWED_DOC_SIGN_PAGE: "viewed_doc_sign_page",
    VIEWED_PAYMENT_OPTIONS_PAGE: "viewed_payment_options_page",
    VIEWED_PAYMENT_SUCCESS_PAGE: "viewed_payment_success_page",
    VIEWED_PAYMENT_FAILED_PAGE: "viewed_payment_failed_page",
    VIEWED_PAYMENT_PENDING_PAGE: "viewed_payment_pending_page",
    VIEWED_PAYMENT_IN_PROGRESS_PAGE: "viewed_payment_in_progress_page",
    VIEWED_ORDER_CONFIRM_PAGE: "viewed_order_confirmation_page",
    VIEWED_TERMS_AND_CONDITION_PAGE: "viewed_terms_and_condition_page",
    VIEWED_MEDIA_PAGE: "viewed_media_page",
    VIEWED_SURVEY_PAGE: "viewed_survey_page",
    VIEWED_ERROR_PAGE: "viewed_error_page",
    VIEWED_KYC_PAGE: "viewed_kyc_page",
    VIEWED_KYC_PEP_PAGE: "viewed_kyc_pep_page",
    VIEWED_KYC_ID_VERIFICATION_PAGE: "viewed_kyc_id_verification_page",
    VIEWED_KYC_PERSONAL_DETAILS_PAGE: "viewed_kyc_personal_details_page",
    VIEWED_KYC_E_SIGN_PAGE: "viewed_kyc_e_sign_page",
    VIEWED_KYC_BANK_DETAILS_PAGE: "viewed_kyc_bank_details_page",
    VIEWED_KYC_STATUS_PAGE: "viewed_kyc_status_page",
    VIEWED_KYC_VERIFIED_PAGE: "viewed_kyc_verified_page",
    VIEWED_MANUAL_VERIFICATION_PAGE: "viewed_kyc_manual_verification_page",
    VIEWED_SIGNUP_PAGE: "viewed_signup_page",
    VIEWED_RISK_DISCLOSURE_PAGE: "viewed_risk_disclosure_page",
    VIEWED_PRIVACY_POLICY_PAGE: "viewed_privacy_policy_page",
    VIEWED_INVITE_ONBOARDING_COMPLETE_PAGE: "viewed_invite_onboarding_complete_page",
    VIEWED_INVITATION_ACCEPT_PAGE: "viewed_invitation_accept_page",
    VIEWED_INVITATION_CONFIRM_PAGE: "viewed_invitation_confirm_page",
    VIEWED_INVITATION_INVALID_PAGE: "viewed_invitation_invalid_page",
    VIEWED_CREATE_NEW_ACCOUNT_PAGE: "viewed_create_new_account_page",
    VIEWED_HOME_PAGE: "viewed_home_page",
    VIEWED_FAQ_PAGE: "viewed_faq_page",
    VIEWED_GLOSSARY_PAGE: "viewed_glossary_page",
    VIEWED_DISCLAIMER_PAGE: "viewed_disclaimer_page",
    VIEWED_CREDIT_EVALUATION_PROCESS_PAGE: "viewed_credit_evaluation_process_page",
    VIEWED_ABOUT_US_PAGE: "viewed_about_us_page",
    VIEWED_ADD_BENEFICIARY_PAGE: "viewed_add_beneficiary_page",
    VIEWED_BOND_FUNDAMENTAL_PAGE: "viewed_bond_fundamentals_page",
    VIEWED_INVESTOR_CHARTER_PAGE: "viewed_investor_charter_page",
    VIEWED_IFA_DISCLAIMER_PAGE: "viewed_ifa_disclaimer_page",
    VIEWED_PRODUCTS_PAGE: "viewed_products_page",
    VIEWED_CONTACT_US_PAGE: "viewed_contact_us_page",
    VIEWED_KYC_ACCOUNT_TYPE_PAGE: "viewed_kyc_account_type_page",
    VIEWED_BLOGS_LISTING_PAGE: "viewed_blogs_listing_page",
    VIEWED_BLOGS_DETAIL_PAGE: "viewed_blogs_detail_page",
    VIEWED_PAYMENT_GATEWAY_RESPONSE_PAGE: "viewed_payment_gateway_response_page",
    VIEWED_INVESTMENT_PROFILE_PAGE: "viewed_investment_profile_page",
    VIEWED_BOND_DIRECTORY_SEARCH_PAGE: "viewed_bond_directory_search_page",
    VIEWED_BOND_DIRECTORY_PAGE: "viewed_bond_directory_page",
};