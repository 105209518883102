import React from 'react';

const InputRef = React.forwardRef(
    (
        {
            register,
            id,
            type,
            labelClass,
            placeholder,
            label,
            error,
            onClick,
            onPaste,
            onBlur,
            borderWeight,
            focusStyle,
            borderColor = 'border-gray-300',
            inputpaddingClassName = 'px-4 py-3',
            errorClassName = '',
            readOnly = false,
            ...inputProps
        },
        ref // Ref passed from parent
    ) => {
        return (
            <>
                {label && (
                    <label
                        className={`block w-full text-left text-gray-900 p4-regular ${labelClass}`}
                    >
                        {label}
                    </label>
                )}
                <input
                    {...(register ? register(id) : {})}
                    ref={ref} // Attach the ref here
                    id={id}
                    {...inputProps}
                    type={type}
                    onClick={onClick}
                    onPaste={onPaste}
                    onBlur={onBlur}
                    className={`p4-regular ${borderColor} ${
                        borderWeight ? borderWeight : 'border-1'
                    } ${inputProps.width ? inputProps.width : 'w-full'} ${
                        inputProps.height ? inputProps.height : 'h-12'
                    } ${inputpaddingClassName} rounded-lg mt-2 box-border ${
                        inputProps.className
                    } ${focusStyle ? focusStyle : ''} ${
                        inputProps?.disabled
                            ? '!bg-gray-100 text-gray-500 !border-1'
                            : ''
                    }`}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    disabled={inputProps?.disabled || false}
                />
                {error && (
                    <div
                        className={`text-left p5-regular mt-2 text-semantic-error-base ${errorClassName}`}
                    >
                        {error.message}
                    </div>
                )}
            </>
        );
    }
);

export default InputRef;
