import moengageEventConstants from "@services/moengage/moengageEventConstants";
import moengageAttributesConstants from "@services/moengage/moengageAttributesConstants";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const setUserData = async ({auth}) => {
    try {
        await window.Moengage.add_unique_user_id(auth?.id).then(async () => {
            updateUserData({auth: auth});
        });
    } catch (error) {
    }
}
const updateUserData = ({auth}) => {
    try {
        updateUserName({name: auth?.name});
        window.Moengage.add_email(auth?.email);
        window.Moengage.add_mobile(auth?.mobile);
        setUserAttribute({
            attributeName: moengageAttributesConstants.USER_ID,
            attributeValue: auth?.userid || auth?.userId || ""
        });
    } catch (error) {
    }
}

const updateUserName = ({name}) => {
    try {
        window.Moengage.add_user_name(name);
    } catch (error) {
    }
}

const setUserAttribute = ({attributeName, attributeValue}) => {
    try {
        window.Moengage.add_user_attribute(attributeName, attributeValue);
    } catch (error) {
    }
}
const logoutUser = () => {
    try {
        let moeData = JSON.parse(
            localStorage.getItem('MOE_DATA')
        );
        if (moeData?.USER_DATA?.attributes?.some(attr => "USER_ATTRIBUTE_UNIQUE_ID" === attr.key)) {
            logEvent({eventName: moengageEventConstants.LOGOUT_SUCCESS,});
            window.Moengage.destroy_session();
        }
    } catch (error) {
    }
}
const updateUserID = ({investorId}) => {
    try {
        window.Moengage.update_unique_user_id(investorId);
    } catch (error) {
    }
}

/// we are using cookies as getAuth will cause circular dependency in case of redirect from digiocker
const logEvent = ({eventName, parameters = {}}) => {
    try {
        const auth = cookies.get('AUTH');
        let moeData = JSON.parse(
            localStorage.getItem('MOE_DATA')
        );
        if (moeData?.USER_DATA?.attributes?.some(attr => "USER_ATTRIBUTE_UNIQUE_ID" === attr.key)) {
            try {
                const uniqueId = moeData?.USER_DATA?.attributes?.find(attr => attr?.key === "USER_ATTRIBUTE_UNIQUE_ID")?.value;
                if (uniqueId !== auth?.id) {
                    if (uniqueId === auth?.userId) {
                        updateUserID({investorId: auth?.id});
                    }
                }
            } catch (error) {

            }
        }
        window.Moengage.track_event(eventName, parameters);
    } catch (error) {
    }
}

module.exports = {
    setUserData,
    updateUserData,
    setUserAttribute,
    logoutUser,
    updateUserID,
    logEvent,
    updateUserName,
};