const nseStatusEnums = {
    APPROVED: 'Approved',
    PENDING_WITH_CHECKER: 'PendingWithChecker',
    RETURNED_BY_CHECKER: 'ReturnedByChecker',
    REJECTED_BY_CHECKER: 'RejectedByChecker',
    PENDING_WITH_EXCHANGE: 'PendingWithExchange',
    REJECTED: 'Rejected',
    RETURNED: 'Returned',
};

Object.freeze(nseStatusEnums);

module.exports = nseStatusEnums;
