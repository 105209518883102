import * as SsrHttpService from '@http/ssr-http.service';
import * as CsrHttpService from '@http/csr-http.service';
import {hasWindow} from '@services/http.service';
import { GET_ISIN_DETAIL_URL, GET_NSE_ELIGIBLE_BANKS } from './urls/nse-rfqs';

export const getIsinDetail = (isin, ctx = null) => {
    let url = GET_ISIN_DETAIL_URL(isin);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getNseEligibleBanks = (ctx = null) => {
    let url = GET_NSE_ELIGIBLE_BANKS();
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};