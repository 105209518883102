import Select, { createFilter, components } from 'react-select';
import { getCustomStyles } from './utils/helper';
import { theme as tailwindTheme } from 'tailwind.config';
const ReactSelect = ({
    label,
    options,
    name,
    error,
    setValue,
    onChange,
    withImage = false,
    register,
    customFormatOptionlabel,
    containerClassName = 'flex items-center',
    optionsWidth = 'w-full',
    components,
    controlStyles = { padding: 10 },
    optionBgColor = tailwindTheme?.colors?.gray?.[100],
    isDisabled = false,
    disabledOpacity = 0.5,
    primaryColor = tailwindTheme?.colors?.primary?.[500],
    height,
    closeMenuOnSelect = true,
    bgDisabled,
    ref = null,
    ...inputProps
}) => {
    const filterConfig = {
        ignoreCase: true,
        ignoreAccents: true,
        trim: true,
        matchFrom: 'any',
    };
    const customTheme = (theme) => {
        return {
            ...theme,
            colors: {
                ...theme.colors,
                primary: tailwindTheme?.colors?.gray?.[100],
            },
        };
    };
    const customStyles = getCustomStyles(
        primaryColor,
        optionBgColor,
        height,
        disabledOpacity,
        controlStyles,
        bgDisabled
    );
    const handleOnChange = (data) => onChange(data);
    return (
        <div className="flex flex-col cursor-pointer">
            {label && (
                <label
                    className={`mb-2  ${inputProps.labelClass
                            ? inputProps.labelClass
                            : 'text-gray-900 p4-regular'
                        }`}
                >
                    {label}
                </label>
            )}
            <Select
                theme={customTheme}
                label="Investor Type"
                options={[
                    {
                        label: 'Indian Resident',
                        value: 'Individual',
                    },
                    // {
                    //     label: 'NRI',
                    //     value: 'Nri',
                    // },
                ]}
                styles={customStyles}
                closeMenuOnSelect={closeMenuOnSelect}
                onChange={handleOnChange}
                menuPosition={inputProps?.menuPlacement || 'bottom'}
                placeholder={
                    inputProps.placeholder ? inputProps.placeholder : 'Select'
                }
                isSearchable={inputProps?.isSearchable || true}
                isClearable={
                    inputProps.isClearable ? inputProps.isClearable : false
                }
                isDisabled={isDisabled}
                components={
                    inputProps.searchImage && {
                        ValueContainer,
                    }
                }
                filterOption={createFilter(filterConfig)}
                customFormatOptionlabel={(val) => {
                    return (
                        <div
                            className={`flex items-center justify-between w-full px-2`}
                        >
                            <Text
                                content={val.label}
                                htmlTag="span"
                                className="flex-1 p4-regular text-basicBlack text-ellipsis"
                            />
                        </div>
                    );
                }}
                formatOptionLabel={(val) => {
                    if (customFormatOptionlabel) {
                        return customFormatOptionlabel(val);
                    }
                }}
                error={error}
                {...inputProps}
            />
            {inputProps.enableRemove && value && (
                <div
                    className="absolute top-[21px] right-14"
                    onClick={inputProps.handleRemove}
                >
                    <Image
                        src={'/images/close-hamburger.svg'}
                        alt={'close'}
                        className="w-5 h-4 cursor-pointer opacity-30 hover:opacity-100"
                    />
                </div>
            )}
        </div>
    );
};

const ValueContainer = ({ children, ...props }) => {
    return (
        components.ValueContainer && (
            <components.ValueContainer {...props}>
                {!!children && (
                    <Image
                        src={Search.src}
                        className={`w-5 h-5 mr-4 self-center cursor-pointer`}
                    />
                )}
                {children}
            </components.ValueContainer>
        )
    );
};

export default ReactSelect;
