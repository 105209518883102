const Input = ({
    register = () => ({ ref: null }),
    id,
    type,
    labelClass,
    placeholder,
    label,
    error,
    onClick,
    onPaste,
    borderWeight,
    focusStyle,
    bgStyle = '',
    borderColor = 'border-gray-200',
    inputpaddingClassName = 'px-4 py-3',
    errorClassName = '',
    readOnly = false,
    inputRef,
    ...inputProps
}) => {
    const inputSize = inputProps?.inputSize
        ? inputProps.inputSize
        : 'p4-regular ';
    const labelSize = labelClass ? labelClass : 'p4-regular ';
    const safeRegister = (id) => {
        const result = register(id);
        return result && typeof result === 'object' ? result : { ref: null };
    };

    const { ref, ...rest } = safeRegister(id);
    return (
        <>
            {label && (
                <label className={`block w-full text-left ${labelSize}`}>
                    {label}
                </label>
            )}
            <input
                {...rest}
                id={id}
                {...inputProps}
                type={type}
                onClick={onClick}
                onPaste={onPaste}
                ref={(e) => {
                    if (ref) {
                        ref(e);
                    }
                    if (inputRef) {
                        inputRef.current = e;
                    }
                }}
                className={`relative  ${inputSize} ${borderColor} ${borderWeight ? borderWeight : 'border-1'
                    } ${inputProps.width ? inputProps.width : 'w-full'} ${inputProps.height ? inputProps.height : 'h-12'
                    } ${inputpaddingClassName} rounded-lg ${label ? "mt-2" : ""} box-border ${inputProps.className
                    } ${focusStyle ? focusStyle : ''} ${inputProps?.disabled
                        ? '!bg-gray-100 text-gray-500 !border-1'
                        : bgStyle
                    }`}
                placeholder={placeholder}
                readOnly={readOnly}
                disabled={inputProps?.disabled || false}
            />
            {error && (
                <div
                    className={`text-left p5-regular mt-2 text-semantic-error-base ${errorClassName}`}
                >
                    {error.message}
                </div>
            )}
        </>
    );
};

export default Input;
