export const typography = {
    '.font-heading': {
        '@apply font-bahnschrift': {},
    },
    '.font-display': {
        '@apply font-bahnschrift': {},
    },
    '.font-paragraph': {
        '@apply font-montserrat': {},
    },
    '.d1': {
        'line-height': '122.222% !important',
        '@apply font-display text-8xl': {},
    },
    '.d1-regular': {
        '@apply d1 font-normal': {},
    },
    '.d1-semibold': {
        '@apply d1 font-semibold': {},
    },
    '.d1-bold': {
        '@apply d1 font-bold': {},
    },
    '.d2': {
        'line-height': '125% !important',
        '@apply font-display text-7xl': {},
    },
    '.d2-regular': {
        '@apply d2 font-normal': {},
    },
    '.d2-semibold': {
        '@apply d2 font-semibold': {},
    },
    '.d2-bold': {
        '@apply d2 font-bold': {},
    },
    '.d3': {
        'line-height': '128.571% !important',
        '@apply font-display text-6xl': {},
    },
    '.d3-regular': {
        '@apply d3 font-normal': {},
    },
    '.d3-semibold': {
        '@apply d3 font-semibold': {},
    },
    '.d3-bold': {
        '@apply d3 font-bold': {},
    },
    '.h1': {
        'line-height': '116.667% !important',
        '@apply font-display text-5xl': {},
    },
    '.h1-regular': {
        '@apply h1 font-normal': {},
    },
    '.h1-semibold': {
        '@apply h1 font-semibold': {},
    },
    '.h1-bold': {
        '@apply h1 font-bold': {},
    },
    '.h2': {
        'line-height': '120% !important',
        '@apply font-display text-4xl': {},
    },
    '.h2-regular': {
        '@apply h2 font-normal': {},
    },
    '.h2-semibold': {
        '@apply h2 font-semibold': {},
    },
    '.h2-bold': {
        '@apply h2 font-bold': {},
    },
    '.h3': {
        'line-height': '125% !important',
        '@apply font-display text-3xl': {},
    },
    '.h3-regular': {
        '@apply h3 font-normal': {},
    },
    '.h3-semibold': {
        '@apply h3 font-semibold': {},
    },
    '.h3-bold': {
        '@apply h3 font-bold': {},
    },
    // h4
    '.h4': {
        'line-height': '133.333% !important',
        '@apply font-display text-2xl': {},
    },
    '.h4-regular': {
        '@apply h4 font-normal': {},
    },
    '.h4-semibold': {
        '@apply h4 font-semibold': {},
    },
    '.h4-bold': {
        '@apply h4 font-bold': {},
    },
    // H5
    '.h5': {
        'line-height': '140% !important',
        '@apply font-display text-xl': {},
    },
    '.h5-regular': {
        '@apply h5 font-normal': {},
    },
    '.h5-semibold': {
        '@apply h5 font-semibold': {},
    },
    '.h5-bold': {
        '@apply h5 font-bold': {},
    },
    // H6
    '.h6': {
        'line-height': '150% !important',
        '@apply font-display text-base': {},
    },
    '.h6-regular': {
        '@apply h6 font-normal': {},
    },
    '.h6-semibold': {
        '@apply h6 font-semibold': {},
    },
    '.h6-bold': {
        '@apply h6 font-bold': {},
    },
    // H7
    '.h7': {
        'line-height': '114.286% !important',
        '@apply font-display text-sm': {},
    },
    '.h7-regular': {
        '@apply h7 font-normal': {},
    },
    '.h7-semibold': {
        '@apply h7 font-semibold': {},
    },
    '.h7-bold': {
        '@apply h7 font-bold': {},
    },
    // P1
    '.p1': {
        'line-height': '140% !important',
        '@apply font-paragraph text-xl': {},
    },
    '.p1-regular': {
        '@apply p1 font-normal': {},
    },
    '.p1-medium': {
        '@apply p1 font-medium': {},
    },
    '.p1-semibold': {
        '@apply p1 font-semibold': {},
    },
    '.p1-bold': {
        '@apply p1 font-bold': {},
    },
    '.p1-underline': {
        '@apply p1-medium underline': {},
    },
    // P2
    '.p2': {
        'line-height': '155.556% !important',
        '@apply font-paragraph text-lg': {},
    },
    '.p2-regular': {
        '@apply p2 font-normal': {},
    },
    '.p2-medium': {
        '@apply p2 font-medium': {},
    },
    '.p2-semibold': {
        '@apply p2 font-semibold': {},
    },
    '.p2-bold': {
        '@apply p2 font-bold': {},
    },
    '.p2-underline': {
        '@apply p2-medium underline': {},
    },
    // P3
    '.p3': {
        'line-height': '150% !important',
        '@apply font-paragraph text-base': {},
    },
    '.p3-regular': {
        '@apply p3 font-normal': {},
    },
    '.p3-medium': {
        '@apply p3 font-medium': {},
    },
    '.p3-semibold': {
        '@apply p3 font-semibold': {},
    },
    '.p3-bold': {
        '@apply p3 font-bold': {},
    },
    '.p3-underline': {
        '@apply p3-medium underline': {},
    },
    // P4
    '.p4': {
        'line-height': '142.857% !important',
        '@apply font-paragraph text-sm': {},
    },
    '.p4-regular': {
        '@apply p4 font-normal': {},
    },
    '.p4-medium': {
        '@apply p4 font-medium': {},
    },
    '.p4-semibold': {
        '@apply p4 font-semibold': {},
    },
    '.p4-bold': {
        '@apply p4 font-bold': {},
    },
    '.p4-underline': {
        '@apply p4-medium underline': {},
    },
    // P5
    '.p5': {
        'line-height': '133.333% !important',
        '@apply font-paragraph text-xs': {},
    },
    '.p5-regular': {
        '@apply p5 font-normal': {},
    },
    '.p5-medium': {
        '@apply p5 font-medium': {},
    },
    '.p5-semibold': {
        '@apply p5 font-semibold': {},
    },
    '.p5-bold': {
        '@apply p5 font-bold': {},
    },
    '.p5-underline': {
        '@apply p5-medium underline': {},
    },
    // P6
    '.p6': {
        'line-height': '127.273% !important',
        '@apply font-paragraph text-small': {},
    },
    '.p6-regular': {
        '@apply p6 font-normal': {},
    },
    '.p6-medium': {
        '@apply p6 font-medium': {},
    },
    '.p6-semibold': {
        '@apply p6 font-semibold': {},
    },
    '.p6-bold': {
        '@apply p6 font-bold': {},
    },
    '.p6-underline': {
        '@apply p6-medium underline': {},
    },
    // P7
    '.p7': {
        'line-height': '120% !important',
        '@apply font-paragraph text-[10px]': {},
    },
    '.p7-regular': {
        '@apply p7 font-normal': {},
    },
    '.p7-medium': {
        '@apply p7 font-medium': {},
    },
    '.p7-semibold': {
        '@apply p7 font-semibold': {},
    },
    '.p7-bold': {
        '@apply p7 font-bold': {},
    },
    '.p7-underline': {
        '@apply p7-medium underline': {},
    },
};
