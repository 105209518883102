// TODO: Don't use this, use from opportunity enum file
const OpportunityTypes = {
    TREASURY_BILL: 'TreasuryBill',
    SOVEREIGN_GOLD_BONDS: 'SovereignGoldBond',
    G_SEC: 'GSec',
    SDL: 'SDL',
    SECURITISED_DEBT_INSTRUMENT: 'SecuritisedDebtInstrument',
    UNLISTED_SECURITISED_DEBT_INSTRUMENT: 'UnlistedSecuritisedDebtInstrument',
    CORPORATE_BOND: 'CorporateBond'
};

Object.freeze(OpportunityTypes);

module.exports = OpportunityTypes;
