import { hasWindow } from '@services/http.service';
import * as CsrHttpService from '@http/csr-http.service';
import * as SsrHttpService from '@http/ssr-http.service';
import {
    GET_CONFIGURATIONS_URL,
    GET_HOME_PAGE_CONFIGURATIONS_URL,
    GET_OPEN_CONFIGURATIONS_URL,
} from '@services/urls/configuration';

export const getHomePageConfigurations = (ctx = null) => {
    if (hasWindow()) {
        return CsrHttpService.getWithOutAuth(
            GET_HOME_PAGE_CONFIGURATIONS_URL()
        );
    }
    return SsrHttpService.getWithOutAuth(
        GET_HOME_PAGE_CONFIGURATIONS_URL(),
        ctx
    );
};

export const getOpenConfigurations = (entityType, ctx) => {
    if (hasWindow()) {
        return CsrHttpService.getWithOutAuth(
            GET_OPEN_CONFIGURATIONS_URL(entityType)
        );
    }
    return SsrHttpService.getWithOutAuth(
        GET_OPEN_CONFIGURATIONS_URL(entityType),
        ctx
    );
};

export const getConfigurations = (configurationType, entityType, ctx) => {
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(
            GET_CONFIGURATIONS_URL(configurationType, entityType)
        );
    }
    return SsrHttpService.getWithAuth(
        GET_CONFIGURATIONS_URL(configurationType, entityType),
        ctx
    );
};
